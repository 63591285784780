<template>
  <div class="containerlog" ref="fullpanel">
    <div class="containerlog_box" ref="insidepanel">
      <div class="containerlog_title">Container Log</div>
      <div class="containerlog_container">
        <div
          class="containerlog_container:single"
          v-for="(t, index) in listid"
          :key="index"
        >
          <div
            class="containerlog_container:single_val"
          >
            {{ t  }}
          </div>
        </div>
      </div>
    </div>
    <Menu />
  </div>
</template>

<script>
import axios from "axios";
import Menu from "@/components/general/nav/menu.vue";
export default {
  data() {
    return {
      listid: "",
      refreshTimer: "",
    };
  },
  components: {
    Menu,
  },
  methods: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    containerlog(t, hometeamname, awayteamname, matchId) {
      this.$store.commit("setpostactive", false);
      this.$store.commit("setliveactive", true);
      this.$store.commit("setIdMatch", matchId);
      this.$store.commit("changeidgame", t);
      this.$store.commit("players", []);
      this.$store.commit("sethometeamname", hometeamname);
      this.$store.commit("setawayteamname", awayteamname);
      this.$store.commit("setOnline");
      this.$store.commit("alarms", []);
      this.$store.commit("alarmsThresolds", []);
      // Legge ed imposta allarmi
      this.getAlarms(t);
      this.$store.commit("setIsActivePlayersControl", false); // Spengo i controlli sui giocatori nel live
      this.$router.push("/field");
      // location.reload();
      //        this.$store.commit('setSnackbar', {color: 'error', text: "PROVA"});
    },
    getLog() {
     // Recupera la lista di tutti i container per i match attivi
      axios
        .get(process.env.VUE_APP_API_URL + "/console/getcontainerlog", {
          params: {
            containerName: this.containerName,
            dockerApiUrl: process.env.VUE_APP_DOCKER_API_URL,
            localApiUrl: process.env.VUE_APP_API_URL
          }
        })
         .then((response) => {
          this.listid = response.data.split(/\r?\n/);
        })
         .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    game() {
      return this.$store.state.game;
    },
    userid() {
      return this.$store.state.user.user.id;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
  },
  created() {
    if (!this.loggedIn()) {
      this.$router.push("/login");
    } else {
      this.containerName = this.$route.params.containerName;
      this.getLog();
      this.refreshTimer = setInterval(this.getLog, 30000);
    }
  },
  destroyed() {
    clearInterval(this.refreshTimer);
  },
};
</script>

<style lang="scss" scoped>
.containerlog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 1000;
  &_box {
    color: var(--color);
    width: auto;
    display: inline-block;
    padding: 30px;
    background-image: var(--background-image);
    border: var(--border);
    top: 20%;
    left: 5%;
    right: 5%;
    bottom: 20%;
    transform: translateY(-20%) scale(0.9);
    position: absolute;
    overflow-y: auto;
    &\:close {
      padding: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  &_title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 80%;
    &\:single {
      width: (100%/1);
      padding: 2px;
      &_val {
        color: var(--color);
        font-weight: bold;
        font-size: 14px;
        text-align: left;
      }
      &_valActive {
      }
    }
  }
  &_text {
    width: 650px;
    text-align: center;
  }
}
</style>
