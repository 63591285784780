var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "fullpanel", staticClass: "containerlog" },
    [
      _c("div", { ref: "insidepanel", staticClass: "containerlog_box" }, [
        _c("div", { staticClass: "containerlog_title" }, [
          _vm._v("Container Log")
        ]),
        _c(
          "div",
          { staticClass: "containerlog_container" },
          _vm._l(_vm.listid, function(t, index) {
            return _c(
              "div",
              { key: index, staticClass: "containerlog_container:single" },
              [
                _c(
                  "div",
                  { staticClass: "containerlog_container:single_val" },
                  [_vm._v(" " + _vm._s(t) + " ")]
                )
              ]
            )
          }),
          0
        )
      ]),
      _c("Menu")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }